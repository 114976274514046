import React, { useEffect } from "react";
import { Redirect } from "@reach/router";

import Layout from "@components/layout";
import Seo from "@components/seo";

const Register = () => {
  useEffect(() => {
    window.location.replace(
      "https://us02web.zoom.us/webinar/register/WN_kutKQjO2QJSL0UjIinGG3A#/registration"
    );
  }, []);

  return (
    // <Layout theme={"fossil"} themeColor={"#555555"}>
    //   <p className="p-10">Test</p>
    // </Layout>

    <div></div>

    // window.location.replace("https://www.gatsbycentral.com/");

    // <Redirect
    //   noThrow
    //   to={`https://us02web.zoom.us/webinar/register/WN_kutKQjO2QJSL0UjIinGG3A#/registration`}
    // />
  );
};

export default Register;
